/* print.css */
@media print {
    /* Style your calendar for printing */
    .fc .fc-media-screen {
        display: none;
    }
    /* Any other styles to make it look good on print */
}
.d-flex {
    display: flex;
    align-items: center;
}

.me-2 {
    margin-right: .5rem; /* Adjust based on your design system */
}
.day-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;
    border: 1px solid #ccc; /* Unselected border color */
}

.day-circle.selected {
    background-color: #007bff; /* Selected background color */
    color: white; /* Selected text color */
    border-color: #007bff; /* Selected border color */
}

/* Additional styling to visually disable the current day */
.day-circle.current-day {
    cursor: not-allowed;
    opacity: 0.5;
}
.fc-event .fc-event-title, .fc-event .fc-event-title-container {
    white-space: normal; /* Allow text to wrap */
    overflow: visible; /* Make sure overflow text is visible */
    height: auto; /* Allow container size to increase based on content */
}

.fc-event {
    line-height: 1.2; /* Adjust line height for readability */
}
.full-width-container input[type="text"] {
    width: 100%;  /* Make the input take the full width of its container */
    box-sizing: border-box;  /* Include padding and border in the width */
}
